export const excelDateToJSDate = (serial) => {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);
  return new Date(
    date_info.getFullYear(),
    date_info.getDate() - 1,
    date_info.getMonth() + 1
  );
};

export const getCurrentFormattedTime = () => {
  const now = new Date();

  // Get date components
  const day = now.getDate();
  const year = now.getFullYear();

  // Get month name
  const month = now.toLocaleString("en-US", { month: "long" });

  // Get time components
  let hours = now.getHours();
  const minutes = now.getMinutes();
  const isAM = hours < 12;
  const period = isAM ? "AM" : "PM";

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // hour '0' should be '12'

  // Format minutes to always be two digits
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Format the final string
  const formattedDate = `${day} ${month} ${year}, ${hours}:${formattedMinutes} ${period}`;

  return formattedDate;
};

export const getKey = (row) => {
  return row.line_number + ".." + row.cpn + ".." + row.mpn;
};

export const getToday = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const now = new Date();

  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const month = months[now.getMonth()];
  const year = now.getFullYear();

  return `${hours}:${minutes} ${day}-${month}-${year}`;
};

export const GET_PRESIGNED_URL =
  "https://b48zkyu0ta.execute-api.ap-southeast-1.amazonaws.com/prod";

export const API_GATEWAY_URL =
  "https://tf65x7jp50.execute-api.ap-southeast-1.amazonaws.com/prod";

export const error_code = {
  444: "Quotation does not exist",
  421: "Authorization Error: You don't have the permission to view/edit this quotation",
};

export const roles = ["ADMIN", "MANAGER", "SALES"];
