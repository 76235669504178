import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  Button,
  FormControl,
  Box,
  Grid,
} from "@mui/material";
import axios from "axios";
import { API_GATEWAY_URL } from "../utils";
import Notification from "../components/Notification";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { Autocomplete } from "@mui/lab";
import useClasses from "../useClasses";
import { StyledHeader } from "../components/StyledHeader";
import { StyledGreenButton } from "../components/StyledButtons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const styles = (theme) => ({
  container: {
    color: "white",
    letterSpacing: "1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  inputContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  header: {
    fontFamily: "AirbnbCereal-Bold",
    fontSize: "1.5rem",
    color: "#212b36",
    padding: "1em 0em 0em 0em",
  },
  subheader: {
    fontFamily: "AirbnbCereal-Medium",
    fontSize: "1rem",
    color: "#41464c",
    padding: "0.5em 0em 0em 0em",
  },
  text_field: {
    width: "300px",
    transitionDuration: "0.6s",
    padding: "0px 1px 0px 1px",
    margin: "20px 2px 2px 2px",
    backgroundColor: "#fff",
    "&:hover fieldset": {
      borderColor: "#212b36",
      transitionDuration: "0.6s",
    },
    "&:focus": {
      borderColor: "#212b36",
    },
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "50px",
    },
  },
  create: {
    margin: "0px",
    marginTop: "30px",
    padding: "10px 20px",
    borderRadius: "8px",
    fontFamily: "AirbnbCereal-Medium",
    fontSize: "12px",
    backgroundColor: "#e1f6e6",
    color: "#3b9c6c",
    "&:hover": {
      backgroundColor: "#b4e8c0",
    },
  },
  upload: {
    transitionDuration: "0.6s",
    padding: "0px 1px 0px 1px",
    margin: "20px 2px 2px 2px",
    backgroundColor: "#fff",
    "&:hover fieldset": {
      borderColor: "#212b36",
      transitionDuration: "0.6s",
    },
    "&:focus": {
      borderColor: "#212b36",
    },
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "50px",
    },
  },
  span: {
    color: "#212b36",
    fontFamily: "AirbnbCereal-Book",
    fontSize: "1em",
    marginRight: "15px",
  },
  formControl: {
    transitionDuration: "0.6s",
    padding: "0px 1px 0px 1px",
    margin: "20px 2px 2px 2px",
    backgroundColor: "#fff",
    "&:hover fieldset": {
      borderColor: "#212b36",
      transitionDuration: "0.6s",
    },
    "&:focus": {
      borderColor: "#212b36",
    },
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "50px",
    },
  },
});

const PriceList = () => {
  const classes = useClasses(styles);
  let navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [display, setDisplay] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [options, setOptions] = useState([
    "Mill-Max",
    "IQD",
    "Cinch",
    "Pickering",
    "EPT",
    "PHOENIX CONTACT",
  ]);
  const [date, setDate] = useState(dayjs());

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");

  useEffect(() => {
    // check cookie authenticaiton
    let cookie = Cookies.get("wiselink_session", null);
    if (cookie == null) {
      navigate("/");
    }
    axios
      .post(`${API_GATEWAY_URL}/authentication/login`, {
        cookie,
      })
      .then((resp) => {
        // login valid, push ahead
        let new_cookie = resp.data.cookie;
        Cookies.set("wiselink_session", new_cookie);
      })
      .catch((error) => {
        navigate("/");
      });
  }, []);

  const handleSnackBar = (sev, msg) => {
    setSeverity(sev);
    setMessage(msg);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleUpload = () => {
    // create company
    if (!selectedFile) {
      handleSnackBar("error", "Please select a file to upload");
      return;
    }

    // upload direct to aws s3
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    });

    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: "wiselink-sg",
        Key:
          "price_list/" +
          display.toUpperCase() +
          "_" +
          date.format("YYYY-MM-DD") +
          ".xlsx",
        Body: file,
      },
    });
    const promise = upload.promise();
    promise
      .then((data) => {
        handleSnackBar(
          "success",
          "Price list successfully uploaded. Prices are being stored as we speak, sit tight!"
        );
      })
      .catch((err) => {
        handleSnackBar("error", "Error processing request: " + err.message);
      });
  };

  // Function to convert file to base64
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Split to remove data URL prefix
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      let base64File = await fileToBase64(file);
      setSelectedFile(base64File);
      setFileName(file.name);
    }
  };

  const handleInputChange = (event, input) => {
    setDisplay(input);
  };

  const handleDownloadTemplate = () => {
    // download direct to aws s3
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    });
    const s3 = new AWS.S3();
    const params = {
      Bucket: 'wiselink-sg',
      Key: 'price_list/price_list_template.xlsx'
    };

    s3.getObject(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
      } else {
        const fileContent = data.Body; // S3 returns the content as a buffer
        const fileName = "price_list_template.xlsx";
        const blob = new Blob([fileContent], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // Trigger the download
        link.click();
      }
    });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          padding: "0em 1em",
          fontFamily: "AirbnbCereal-Medium",
        }}
      >
        <StyledHeader>Supplier Price List</StyledHeader>
        <Typography
          sx={{
            fontFamily: "AirbnbCereal-Book",
            fontSize: "1rem",
            color: "#41464c",
          }}
        >
          {display !== null && display.length > 0
            ? "Company name will be: " + display
            : "File name will be used as company name"}
        </Typography>
        <Grid container sx={{ marginTop: "1em" }} xs={12} lg={4} spacing={2}>
          <Grid container item xs={12} lg={12}>
            <DatePicker
              fullWidth
              value={date}
              label="Effective Date"
              format="DD/MM/YYYY" 
              onChange={(value) => {
                setDate(value);
              }}
            />
            <Grid item xs={12} lg={3} sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
              <StyledGreenButton onClick={handleDownloadTemplate} sx={{ width: '100%' }}>
                download template
              </StyledGreenButton>
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={12}>
            <FormControl fullWidth>
              <Autocomplete
                freeSolo={false}
                options={options}
                onChange={handleInputChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        fontFamily: "AirbnbCereal-Book",
                        fontSize: "0.9em",
                      },
                    }}
                    label="Price List"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid container item xs={12} lg={12}>
            <input type="file" onChange={handleFileChange} />
          </Grid>
          <Grid container item xs={12} lg={12}>
            <StyledGreenButton onClick={handleUpload}>Upload</StyledGreenButton>
          </Grid>
        </Grid>
        <Notification
          close={handleCloseSnackbar}
          open={openSnackbar}
          message={message}
          severity={severity}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default PriceList;
