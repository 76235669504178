import React, { useState } from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    TablePagination,
    Box,
    IconButton,
    TextField,
} from "@mui/material";
import {
    Delete,
    Edit,
    Save,
    Cancel,
} from "@mui/icons-material";
import useClasses from "../useClasses";
import { API_GATEWAY_URL, getToday } from "../utils";
import { StyledTableCell } from "../components/StyledTableComponents";
import axios from "axios";
import Notification from "../components/Notification";

const styles = (theme) => ({
    resultContainer: {
        marginTop: "2em",
        display: "flex",
        width: "100%",
        alignItems: "flex-start",
        flexDirection: "column",
    },
    table: {
        backgroundColor: "#f3f6f8",
        color: "white",
        "&::before, &::after": {
            borderColor: "red",
        },
        "& .MuiTableCell-sizeSmall": {
            padding: "20px",
        },
        overflow: "hidden",
    },
    tableContainer: {
        boxShadow: "0px 0px 30px 2px rgba(212, 212, 212, 0.3)",
        borderRadius: "8px",
        width: "80%",
    },
    tableRowHeader: {
        border: "none",
        whiteSpace: "nowrap",
        backgroundColor: "#f3f6f8",
    },
    tableHeaderText: {
        color: "#637381",
        fontFamily: "AirbnbCereal-Medium",
        textAlign: "left",
        fontSize: "12px",
    }
});

const SupplierPriceTable = ({
    searchResults,
    totalCount,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    onUpdateSearchResults,
    onDeleteSearchResults,
}) => {
    const [isDeleteMode, setIsDeleteMode] = useState(false); // Track is user performing delete
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const classes = useClasses(styles);
    const [editIndex, setEditIndex] = useState(-1); // Track the index of the editing row
    const [editedData, setEditedData] = useState({}); // Store edited data for the row

    const handleSnackBar = (sev, msg) => {
        setSeverity(sev);
        setMessage(msg);
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    // Function to handle row deletion
    const handleDeleteRow = async (searchResults, index) => {
        await axios
            .post(
                `${API_GATEWAY_URL}/supplier/price-list/delete-price`, {
                deletedRowData: searchResults[index]
            })
            .then((response) => {
                if (response.status == 200) {
                    setEditedData({});
                    return response.data;
                }
            })
            .catch((error) => {
                console.error("Failed to delete row data", error);
                throw error;
            });
    };


    // Function to handle row update
    const updateRowData = async (updatedRowData, searchResults) => {
        await axios
            .post(
                `${API_GATEWAY_URL}/supplier/price-list/update-price`, {
                updatedRowData,
                originalRowData: searchResults[editIndex]
            })
            .then((response) => {
                if (response.status === 200) {
                    setEditedData({});
                    return response.data;
                }
            })
            .catch((error) => {
                console.error("Failed to update row data", error);
                throw error;
            });
    };


    const handleSave = () => {
        // Compare original row data with edited data to check for changes
        const isChanged = Object.keys(editedData).some(
            (key) => editedData[key] !== searchResults[editIndex][key]
        );
        if (isChanged) {
            editedData.updated_date = getToday();
            updateRowData(editedData, searchResults)
                .then(() => {
                    handleSnackBar(
                        "success",
                        "The price list has been successfully updated!"
                    );
                    onUpdateSearchResults(editIndex, editedData);
                    setEditIndex(-1);
                })
                .catch((error) => {
                    handleSnackBar(
                        "error",
                        "Error, duplicated supplier, mpn and moq exists!"
                    );
                    console.error("Failed to update row:", error);
                });
            setEditIndex(-1);
        } else {
            handleSnackBar(
                "warning",
                "No changes made to the data. Please update the fields if you want to save any changes."
            );
            console.log("No changes detected, save cancelled.");
            setEditIndex(-1);
        }
    };

    const handleFieldChange = (e, field) => {
        setEditedData({
            ...editedData,
            [field]: e.target.value || "",
        });
    };

    const handleCancel = () => {
        setEditIndex(-1);
        setEditedData({});
    };

    const handleEdit = (index, row) => {
        setIsDeleteMode(false);
        setEditIndex(index);
        setEditedData(row);
    };

    const handleDelete = (index, row) => {
        setIsDeleteMode(true);
        setEditIndex(index);
        setEditedData(row);
        handleDeleteRow(searchResults, index).then(() => {
            handleSnackBar(
                "success",
                "The supplier price list has been successfully deleted!"
            );
            onDeleteSearchResults(index);
            setEditIndex(-1);
        })
            .catch((error) => {
                handleSnackBar(
                    "error",
                    "Failed to delete the supplier price list. Please try again."
                );
                console.error("Failed to delete row", error);
            });
    };

    const handlePageChange = (event, newPage) => {
        onPageChange(event, newPage);
        setEditIndex(-1);
        setEditedData({});
    };

    return (
        <Box className={classes.resultContainer}>
            <Notification
                close={handleCloseSnackbar}
                open={openSnackbar}
                message={message}
                severity={severity}
            />
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow className={classes.tableRowHeader}>
                            <TableCell style={{ paddingLeft: "30px" }}>Supplier</TableCell>
                            <TableCell>Mpn</TableCell>
                            <TableCell>Moq</TableCell>
                            <TableCell>Unit Price</TableCell>
                            <TableCell>SPQ</TableCell>
                            <TableCell>Packaging</TableCell>
                            <TableCell>Lead Time</TableCell>
                            <TableCell>Cancellation</TableCell>
                            <TableCell>Last Update</TableCell>
                            <TableCell>Expiry Date</TableCell>
                            <TableCell>Actions</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchResults.map((result, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    backgroundColor: "#fff",
                                    border: "#f3f6f8 dotted 2px",
                                }}
                            >
                                <StyledTableCell align="left" style={{ paddingLeft: "30px" }}>
                                    {result.manufacturer}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {editIndex === index && !isDeleteMode ? (
                                        <TextField
                                            value={editedData.mpn ?? result.mpn}
                                            onChange={(e) => handleFieldChange(e, "mpn")}
                                            size="small"
                                        />
                                    ) : (
                                        result.mpn
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {editIndex === index && !isDeleteMode ? (
                                        <TextField
                                            value={editedData.moq ?? result.moq}
                                            onChange={(e) => handleFieldChange(e, "moq")}
                                            size="small"
                                        />
                                    ) : (
                                        result.moq
                                    )}

                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {editIndex === index && !isDeleteMode ? (
                                        <TextField
                                            value={editedData.unit_price ?? result.unit_price}
                                            onChange={(e) => handleFieldChange(e, "unit_price")}
                                            size="small"
                                        />
                                    ) : (
                                        result.unit_price
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {editIndex === index && !isDeleteMode ? (
                                        <TextField
                                            value={editedData.spq ?? result.spq}
                                            onChange={(e) => handleFieldChange(e, "spq")}
                                            size="small"
                                        />
                                    ) : (
                                        result.spq
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {editIndex === index && !isDeleteMode ? (
                                        <TextField
                                            value={editedData.packaging ?? result.packaging}
                                            onChange={(e) => handleFieldChange(e, "packaging")}
                                            size="small"
                                        />
                                    ) : (
                                        result.packaging
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {editIndex === index && !isDeleteMode ? (
                                        <TextField
                                            value={editedData.lead_time ?? result.lead_time}
                                            onChange={(e) => handleFieldChange(e, "lead_time")}
                                            size="small"
                                        />
                                    ) : (
                                        result.lead_time
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {editIndex === index && !isDeleteMode ? (
                                        <TextField
                                            value={editedData.cancellation ?? result.cancellation}
                                            onChange={(e) => handleFieldChange(e, "cancellation")}
                                            size="small"
                                        />
                                    ) : (
                                        result.cancellation
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {editIndex === index && !isDeleteMode ? (
                                        <TextField
                                            value={editedData.updated_date ?? result.updated_date}
                                            onChange={(e) => handleFieldChange(e, "updated_date")}
                                            size="small"
                                        />
                                    ) : (
                                        result.updated_date
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {editIndex === index && !isDeleteMode ? (
                                        <TextField
                                            value={editedData.expiry_date ?? result.expiry_date}
                                            onChange={(e) => handleFieldChange(e, "expiry_date")}
                                            size="small"
                                        />
                                    ) : (
                                        result.expiry_date
                                    )}
                                </StyledTableCell>
                                <StyledTableCell className={classes.table_cell} align="left">
                                    {editIndex === index ? (
                                        <>
                                            <IconButton onClick={handleSave} color="primary">
                                                <Save />
                                            </IconButton>
                                            <IconButton onClick={handleCancel} color="secondary">
                                                <Cancel />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <>
                                            <IconButton onClick={() => handleEdit(index, result)}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(index, result)}>
                                                <Delete color="error" />
                                            </IconButton>
                                        </>
                                    )}

                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            />
        </Box>
    );
};

export default SupplierPriceTable;
