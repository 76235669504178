import { Select, styled } from "@mui/material";

export const StyledSelect = styled(Select)(
  ({ theme }) => `
          width: 100%;
          transition-duration: 0.6s;
          background-color: #fff;
          :hover fieldset {
              borderColor: #212b36;
              transitionDuration: 0.6s;
          },
          :focus {
              borderColor: #212b36;
          },
      `
);
