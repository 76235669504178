import React, { useState } from "react";
import {
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { API_GATEWAY_URL } from "../utils";
import Notification from "../components/Notification";
import { Autocomplete } from "@mui/lab";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useClasses from "../useClasses";
import { StyledHeader } from "../components/StyledHeader";
import { StyledTextField } from "../components/StyledTextField";
import {
  StyledRedButton,
  StyledGreenButton,
} from "../components/StyledButtons";

const styles = (theme) => ({
  wrapper: {
    padding: "1em 2em",
    fontFamily: "AirbnbCereal-Medium",
  },
  container: {
    color: "white",
    letterSpacing: "1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  inputContainer: {
    display: "flex",
    width: "100%",
  },
  text_field: {
    fontFamily: "AirbnbCereal-Book",
    width: "300px",
    transitionDuration: "0.6s",
    padding: "0px 1px 0px 1px",
    margin: "20px 2px 2px 2px",
    backgroundColor: "#fff",
    "&:hover fieldset": {
      borderColor: "#212b36",
      transitionDuration: "0.6s",
    },
    "&:focus": {
      borderColor: "#212b36",
    },
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "50px",
    },
  },
  text_font: {
    color: "#212b36",
    fontFamily: "AirbnbCereal-Book",
    fontSize: "1em",
  },
  create: {
    margin: "0px",
    marginTop: "30px",
    padding: "10px 20px",
    borderRadius: "8px",
    fontFamily: "AirbnbCereal-Medium",
    fontSize: "12px",
    backgroundColor: "#e1f6e6",
    color: "#3b9c6c",
    "&:hover": {
      backgroundColor: "#b4e8c0",
    },
  },
  clear: {
    margin: "0px",
    marginTop: "30px",
    marginRight: "10px",
    padding: "10px 20px",
    borderRadius: "8px",
    fontFamily: "AirbnbCereal-Medium",
    fontSize: "12px",
    backgroundColor: "#fdecea",
    color: "#f44336",
    "&:hover": {
      backgroundColor: "#fbc6c2",
    },
  },
  formControl: {
    transitionDuration: "0.6s",
    padding: "0px 1px 0px 1px",
    margin: "20px 2px 2px 2px",
    backgroundColor: "#fff",
    "&:hover fieldset": {
      borderColor: "#212b36",
      transitionDuration: "0.6s",
    },
    "&:focus": {
      borderColor: "#212b36",
    },
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "50px",
    },
  },
});

const Contact = () => {
  const classes = useClasses(styles);
  let navigate = useNavigate();

  const [options, setOptions] = useState([]);
  const [contactPersonOptions, setContactPersonOptions] = useState([]);
  const [departments, setDepartments] = useState([
    "SOURCING",
    "PURCHASING",
    "ENGINEERING",
  ]);

  const [company, setCompany] = useState("");
  const [salesContact, setSalesContact] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [extension, setExtension] = useState("");
  const [department, setDepartment] = useState("");
  const [officeNumber, setOfficeNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [remarks, setRemarks] = useState("");
  const [position, setPosition] = useState("");
  const [creator, setCreator] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");

  const handleSnackBar = (sev, msg) => {
    setSeverity(sev);
    setMessage(msg);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    // check cookie authenticaiton
    let cookie = Cookies.get("wiselink_session", null);
    if (cookie == null) {
      navigate("/");
    }
    axios
      .post(`${API_GATEWAY_URL}/authentication/login`, {
        cookie,
      })
      .then((resp) => {
        // login valid, push ahead
        let new_cookie = resp.data.cookie;
        let creator = resp.data.email.split("@")[0];
        Cookies.set("wiselink_session", new_cookie);
        setCreator(creator);
        // make call to retrieve all companies
        axios
          .get(`${API_GATEWAY_URL}/company`, {
            params: {
              creator,
              role: resp.data.role,
            },
          })
          .then(function (response) {
            let english = [];
            for (let item of response.data) {
              english.push(item.english);
            }
            setOptions(english);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((error) => {
        navigate("/");
      });
  }, []);

  const handleCreate = () => {
    // create contact
    if (!salesContact || salesContact.trim().length == 0) {
      alert("Please ensure Contact Name is filled in");
      return;
    }
    axios
      .post(`${API_GATEWAY_URL}/contact`, {
        company: company.toUpperCase(),
        contact_name: salesContact.toUpperCase(),
        extension: extension,
        department: department,
        office_number: officeNumber,
        phone: mobileNumber,
        email: email.toUpperCase(),
        remarks: remarks.trim(),
        position: position.toUpperCase(),
        creator: creator,
      })
      .then(function (response) {
        handleSnackBar("success", "Successful Created / Updated Contact");
      })
      .catch(function (error) {
        handleSnackBar("error", "Error processing request, please try again");
      });
  };

  const handleCompanySelect = (e) => {
    console.log("Selected Company: ", e.target.value);
    setCompany(e.target.value);
    // retrieve contact list
    axios
      .get(`${API_GATEWAY_URL}/contact`, {
        params: {
          company: e.target.value,
        },
      })
      .then(function (response) {
        let contact_list = [];
        for (let contact of response.data) {
          contact_list.push(contact.contact_name);
        }
        console.log(contact_list);
        setContactPersonOptions(contact_list);
      })
      .catch(function (error) {
        handleSnackBar(
          "error",
          "Error retrieving contact information, try again later"
        );
      });
  };

  const handleContactPersonChange = (event, input) => {
    let value = input.toUpperCase();
    setSalesContact(value);
    // only perform a search if the previous value is different
    if (selectedContact != value && contactPersonOptions.includes(value)) {
      setSelectedContact(value);
      // perform a search to retrieve the specific company options
      axios
        .get(`${API_GATEWAY_URL}/contact`, {
          params: {
            contact_name: value,
          },
        })
        .then((response) => {
          if (response.data.length !== 0) {
            let contact = response.data[0];
            setDepartment(contact.department);
            setOfficeNumber(contact.office_number);
            setEmail(contact.email);
            setRemarks(contact.remarks);
            setExtension(contact.extension);
            setMobileNumber(contact.phone);
            setPosition(contact.position);
          } else {
            // do something else here
            handleSnackBar(
              "error",
              "Error retrieving contact information, try again later"
            );
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const reset = () => {
    setCompany("");
    setSalesContact("");
    setDepartment("");
    setOfficeNumber("");
    setExtension("");
    setMobileNumber("");
    setEmail("");
    setRemarks("");
    setPosition("");
  };

  return (
    <Box sx={{ fontFamily: "AirbnbCereal-Medium", padding: "0em 1em" }}>
      <StyledHeader>Contact Person</StyledHeader>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: "1em",
        }}
      >
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel>Company</InputLabel>
              <Select
                autoWidth
                value={company}
                label="Company"
                onChange={handleCompanySelect}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  getContentAnchorEl: null,
                }}
                style={{ fontFamily: "AirbnbCereal-Book", fontSize: "1em" }}
              >
                {options.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <Autocomplete
              freeSolo
              options={contactPersonOptions}
              inputValue={salesContact}
              onInputChange={handleContactPersonChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      fontFamily: "AirbnbCereal-Book",
                      fontSize: "1em",
                    },
                  }}
                  label="Contact Name"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel>Department</InputLabel>
              <Select
                autoWidth
                value={department}
                label="Department"
                onChange={(e) => setDepartment(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  getContentAnchorEl: null,
                }}
                style={{ fontFamily: "AirbnbCereal-Book", fontSize: "1em" }}
              >
                {departments.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Office Number"
              value={officeNumber}
              onChange={(e) => setOfficeNumber(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Extension"
              value={extension}
              onChange={(e) => setExtension(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12} spacing={2}>
          <Grid item xs={4} lg={2}>
            <StyledRedButton onClick={reset}>Clear</StyledRedButton>
          </Grid>
          <Grid item xs={8} lg={2}>
            <StyledGreenButton onClick={handleCreate}>
              Create / Update
            </StyledGreenButton>
          </Grid>
        </Grid>
      </Grid>
      <Notification
        close={handleCloseSnackbar}
        open={openSnackbar}
        message={message}
        severity={severity}
      />
    </Box>
  );
};

export default Contact;
