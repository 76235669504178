import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  styled,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { API_GATEWAY_URL, getToday } from "../utils";
import axios from "axios";
import Notification from "../components/Notification";
import { Autocomplete } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { StyledTextField } from "../components/StyledTextField";
import { StyledSelect } from "../components/StyledSelect";
import { StyledHeader } from "../components/StyledHeader";
import {
  StyledGreenButton,
  StyledOrangeButton,
  StyledRedButton,
} from "../components/StyledButtons";

const Company = () => {
  let navigate = useNavigate();
  const [company, setCompany] = useState("");
  const [companyChinese, setCompanyChinese] = useState("");
  const [officeAddress, setOfficeAddress] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [shipmentTerms, setShipmentTerms] = useState("");
  const [accountManager, setAccountManager] = useState("");
  const [paymentOptions, setPaymentOptions] = useState([
    "TT IN ADVANCE",
    "TT UPON SHIPMENT",
    "30% DEPOSIT / 70% BEFORE SHIPMENT",
    "50% DEPOSIT / 50% BEFORE SHIPMENT",
    "30 DAYS",
    "60 DAYS",
    "90 DAYS",
  ]);
  const [shipmentOptions, setShipmentOptions] = useState([
    "EXW SINGAPORE",
    "FCA SINGAPORE",
    "DAP HONG KONG",
    "DAP CHINA",
    "DDU CHINA",
    "DAP DESTINATION",
    "FOB SINGAPORE",
  ]);
  const [options, setOptions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [types, setTypes] = useState(["EMS", "DISTY", "OEM", "CA", "OTHERS"]);
  const [companyCreator, setCompanyCreator] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");

  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyChineseOptions, setCompanyChineseOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedCompanyChinese, setSelectedCompanyChinese] = useState("");

  const [creator, setCreator] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    // check cookie authenticaiton
    let cookie = Cookies.get("wiselink_session", null);
    if (cookie == null) {
      navigate("/");
    }
    axios
      .post(`${API_GATEWAY_URL}/authentication/login`, {
        cookie,
      })
      .then((resp) => {
        // login valid, push ahead
        let new_cookie = resp.data.cookie;
        let creator = resp.data.email.split("@")[0];
        setCreator(creator);
        Cookies.set("wiselink_session", new_cookie);
        // perform company search here & set
        axios
          .get(`${API_GATEWAY_URL}/company`, {
            params: {
              creator,
              role: resp.data.role,
            },
          })
          .then((response) => {
            let english = [];
            let chinese = [];
            for (let item of response.data) {
              english.push(item.english);
              if (item.chinese.trim() != "" && item.chinese.trim() != "N/A") {
                chinese.push(item.chinese);
              }
            }
            setCompanyOptions(english);
            setCompanyChineseOptions(chinese);
          })
          .catch((error) => {
            console.log(error);
          });

        axios
          .get(`${API_GATEWAY_URL}/account/get-sales-people`)
          .then((response) => {
            let sales = [];
            for (let i = 0; i < response.data.options.length; i++) {
              sales.push(response.data.options[i]["name"]);
            }
            setOptions(sales);
          });
      })
      .catch((error) => {
        navigate("/");
      });
  }, []);

  const handleSnackBar = (sev, msg) => {
    setSeverity(sev);
    setMessage(msg);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCreate = () => {
    let last_updated = getToday();
    axios
      .post(`${API_GATEWAY_URL}/company`, {
        company: company.toUpperCase(),
        office_address: officeAddress.toUpperCase(),
        payment_terms: paymentTerms.toUpperCase(),
        shipment_terms: shipmentTerms.toUpperCase(),
        account_manager: accountManager.toUpperCase(),
        company_mandarin: companyChinese.toUpperCase(),
        type: type.toUpperCase(),
        creator: creator,
        last_updated: last_updated,
        action: "CREATE",
      })
      .then(function (response) {
        console.log(response.data);
        setCompanyCreator(creator);
        setLastUpdated(last_updated);
        handleSnackBar("success", "Successful Created / Updated Company");
      })
      .catch(function (error) {
        handleSnackBar("error", "Error processing request, please try again");
      });
  };

  const handleUpdate = () => {
    let last_updated = getToday();
    axios
      .post(`${API_GATEWAY_URL}/company`, {
        company: company.toUpperCase(),
        original: selectedCompany.toUpperCase(),
        office_address: officeAddress.toUpperCase(),
        payment_terms: paymentTerms.toUpperCase(),
        shipment_terms: shipmentTerms.toUpperCase(),
        account_manager: accountManager.toUpperCase(),
        company_mandarin: companyChinese.toUpperCase(),
        type: type.toUpperCase(),
        creator: creator,
        last_updated: last_updated,
        action: "UPDATE",
      })
      .then(function (response) {
        handleSnackBar("success", "Successful Created / Updated Company");
        setCompanyCreator(creator);
        setLastUpdated(last_updated);
      })
      .catch(function (error) {
        handleSnackBar("error", "Error processing request, please try again");
      });
  };

  const handleEnglishSearch = () => {
    // track original name
    setSelectedCompany(company);
    axios
      .post(`${API_GATEWAY_URL}/company/search`, {
        company: company,
        language: "english",
        type: "exact",
      })
      .then(function (response) {
        if (Object.keys(response.data).length !== 0) {
          console.log(response.data);
          setAccountManager(response.data.account_manager);
          setPaymentTerms(response.data.payment_terms);
          setShipmentTerms(response.data.shipment_terms);
          setOfficeAddress(response.data.office_address);
          setCompanyChinese(response.data.company_mandarin);
          setCompany(response.data.company);
          setType(response.data.type);
          setCompanyCreator(response.data.creator);
          setLastUpdated(
            response.data.last_updated ? response.data.last_updated : "-"
          );
        } else {
          // do something else here
          handleSnackBar("error", "Company does not exist");
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const handleChineseSearch = () => {
    // track original name chinese
    setSelectedCompanyChinese(companyChinese);
    axios
      .post(`${API_GATEWAY_URL}/company/search`, {
        company: companyChinese,
        language: "chinese",
        type: "exact",
      })
      .then(function (response) {
        if (Object.keys(response.data).length !== 0) {
          console.log(response.data);
          setAccountManager(response.data.account_manager);
          setPaymentTerms(response.data.payment_terms);
          setShipmentTerms(response.data.shipment_terms);
          setOfficeAddress(response.data.office_address);
          setCompanyChinese(response.data.company_mandarin);
          setCompany(response.data.company);
          setType(response.data.type);
          setCompanyCreator(response.data.creator);
          setLastUpdated(
            response.data.last_updated ? response.data.last_updated : "-"
          );
        } else {
          // do something else here
          handleSnackBar("error", "Company does not exist");
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const reset = () => {
    setCompany("");
    setCompanyChinese("");
    setType("");
    setOfficeAddress("");
    setPaymentTerms("");
    setShipmentTerms("");
    setAccountManager("");
  };

  return (
    <Box sx={{ fontFamily: "AirbnbCereal-Medium", padding: "0em 1em" }}>
      <StyledHeader>Company</StyledHeader>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: "1em",
        }}
      >
        <Grid container item xs={12} lg={12} spacing={1}>
          <Grid item xs={9} lg={3}>
            <Autocomplete
              freeSolo
              options={companyOptions}
              value={company}
              onInputChange={(e, value) => {
                setCompany(value);
              }}
              sx={{
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontFamily: "AirbnbCereal-Book", fontSize: "1em" },
                  }}
                  label="Company Name"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={3}
            lg={1}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <StyledOrangeButton onClick={handleEnglishSearch}>
              Search
            </StyledOrangeButton>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12} spacing={1}>
          <Grid item xs={9} lg={3}>
            <Autocomplete
              freeSolo
              value={companyChinese}
              options={companyChineseOptions}
              // inputValue={companyChinese}
              onInputChange={(e, value) => setCompanyChinese(value)}
              sx={{
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontFamily: "AirbnbCereal-Book", fontSize: "1em" },
                  }}
                  label="Company (中文)"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={3}
            lg={1}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <StyledOrangeButton onClick={handleChineseSearch}>
              Search
            </StyledOrangeButton>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <StyledSelect
                autoWidth
                value={type}
                label="Type"
                onChange={(e) => setType(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {types.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Office Address"
              value={officeAddress}
              onChange={(e) => setOfficeAddress(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel>Payment Terms</InputLabel>
              <StyledSelect
                autoWidth
                value={paymentTerms}
                label="Payment Terms"
                onChange={(e) => setPaymentTerms(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {paymentOptions.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel>Shipment Terms</InputLabel>
              <StyledSelect
                autoWidth
                value={shipmentTerms}
                label="Shipment Terms"
                onChange={(e) => setShipmentTerms(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {shipmentOptions.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel>Account Manager</InputLabel>
              <StyledSelect
                autoWidth
                value={accountManager}
                label="Account Manager"
                onChange={(e) => setAccountManager(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {options.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12} spacing={2}>
          <Grid item xs={4} lg={1}>
            <StyledRedButton onClick={reset}>Clear</StyledRedButton>
          </Grid>
          <Grid item xs={4} lg={1}>
            <StyledGreenButton onClick={handleCreate}>Create</StyledGreenButton>
          </Grid>
          <Grid item xs={4} lg={1}>
            <StyledOrangeButton onClick={handleUpdate}>
              Update
            </StyledOrangeButton>
          </Grid>
        </Grid>
        {companyCreator != "" && (
          <Grid container item xs={12} lg={12}>
            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  fontFamily: "AirbnbCereal-Medium",
                  fontSize: "1em",
                  paddingTop: "1em",
                  color: "#1f2c37",
                }}
              >
                Creator: {companyCreator.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  fontFamily: "AirbnbCereal-Medium",
                  fontSize: "1em",
                  paddingTop: "0.2em",
                  color: "#1f2c37",
                }}
              >
                Last Updated: {lastUpdated.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Notification
        close={handleCloseSnackbar}
        open={openSnackbar}
        message={message}
        severity={severity}
      />
    </Box>
  );
};

export default Company;
