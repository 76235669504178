import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Typography,
  Box,
  OutlinedInput,
  Button,
} from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import { styles } from "../styles";
import { StyledTextField } from "./StyledTextField";
import { StyledSelect } from "./StyledSelect";
import useClasses from "../useClasses";
import { API_GATEWAY_URL, roles } from "../utils";
import axios from "axios";

export default function ({ staff, open, close, save }) {
  const classes = useClasses(styles);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [visibility, setVisibility] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [sales, setSales] = useState([]);

  useEffect(() => {
    setName(staff.name);
    setRole(staff.role);
    setVisibility(staff.visibility);
    setEmail(staff.email);
    setPassword(staff.password);

    axios
      .get(`${API_GATEWAY_URL}/account/get-sales-people`)
      .then((response) => {
        let results = [];
        for (let i = 0; i < response.data.options.length; i++) {
          results.push(response.data.options[i]["name"]);
        }
        setSales(results);
      });
  }, [staff]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setVisibility(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Modal open={open} onClose={close}>
      <Box className={classes.edit_container}>
        <Typography
          sx={{
            padding: "3px 2px 0px 2px",
            color: "#212b36",
            fontFamily: "AirbnbCereal-Bold",
            fontSize: "20px",
            marginBottom: "1em",
          }}
          //   className={`${classes.modal_header} ${classes.pd_modal_header}`}
        >
          Staff
        </Typography>

        <StyledTextField
          disabled
          fullWidth
          variant="outlined"
          value={email}
          label="Email"
          style={{ marginBottom: "1em" }}
        />

        <StyledTextField
          fullWidth
          variant="outlined"
          value={name}
          label="Staff Name"
          onChange={(e) => {
            setName(e.target.value);
          }}
          style={{ marginBottom: "1em" }}
        />

        <FormControl fullWidth style={{ marginBottom: "1em" }}>
          <InputLabel>Role</InputLabel>
          <StyledSelect
            autoWidth
            value={role}
            label="Role"
            onChange={(e) => setRole(e.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              getContentAnchorEl: null,
            }}
          >
            {roles.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </StyledSelect>
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: "1em" }}>
          <InputLabel>Visibility</InputLabel>
          <StyledSelect
            disabled={role != "MANAGER"}
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={visibility}
            onChange={handleChange}
            input={<OutlinedInput label="Name" />}
          >
            {sales.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </StyledSelect>
          {role != "MANAGER" && (
            <Typography
              sx={{
                marginTop: "3px",
                fontFamily: "AirbnbCereal-Book",
                textAlign: "right",
                fontSize: "14px",
                color: "red",
              }}
            >
              Admins - full visibility / Sales - personal data
            </Typography>
          )}
        </FormControl>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "1em",
          }}
        >
          <Button
            onClick={() => {
              save(name, role, visibility, email, password);
              close();
            }}
            variant="contained"
            sx={{
              transition: "0.3s",
              fontFamily: "AirbnbCereal-Medium",
              padding: "0.5em 2em 0.5em 2em",
              borderRadius: "8px",
              fontSize: "13px",
              "&:hover": {
                transition: "0.3s",
              },
              color: "#3b9c6c",
              boxShadow: "none",
              backgroundColor: "#e1f6e7",
              marginRight: "10px",
              "&:hover": {
                backgroundColor: "#b4e8c0",
              },
            }}
          >
            Save
          </Button>
          <Button
            onClick={close}
            variant="contained"
            sx={{
              transition: "0.3s",
              fontFamily: "AirbnbCereal-Medium",
              padding: "0.5em 2em 0.5em 2em",
              borderRadius: "8px",
              fontSize: "13px",
              "&:hover": {
                transition: "0.3s",
              },
              color: "#f44336",
              boxShadow: "none",
              backgroundColor: "#fdecea",
              "&:hover": {
                backgroundColor: "#fbc6c2",
              },
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
