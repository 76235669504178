import React, { useEffect, useState, useRef } from "react";
import {
    Grid,
} from "@mui/material";
import {
    MenuButtonBold,
    MenuButtonItalic,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectHeading,
    MenuSelectFontFamily,
    RichTextEditor,
    FontSize,
    MenuSelectFontSize,
    MenuButtonUnderline,
    MenuButtonTextColor,
    MenuButtonHighlightColor,
    MenuSelectTextAlign,
    MenuButtonOrderedList,
    MenuButtonBulletedList,
    MenuButtonHorizontalRule,
    MenuButtonUndo,
    MenuButtonRedo,
} from "mui-tiptap";
import FontFamily from '@tiptap/extension-font-family'
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import { Underline } from "@tiptap/extension-underline";
import { Highlight } from '@tiptap/extension-highlight';
import { Link } from '@tiptap/extension-link';
import { TextAlign } from "@tiptap/extension-text-align";
import { API_GATEWAY_URL, getToday } from "../utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { StyledHeader } from "../components/StyledHeader";
import {
    StyledGreenButton,
} from "../components/StyledButtons";
import StarterKit from "@tiptap/starter-kit";
import AWS from "aws-sdk";

const Instruction = () => {

    const rteRef = useRef(null);
    let navigate = useNavigate();
    const [role, setRole] = useState("");

    useEffect(() => {
        // check cookie authenticaiton
        let cookie = Cookies.get("wiselink_session", null);
        if (cookie == null) {
            navigate("/");
        }
        axios
            .post(`${API_GATEWAY_URL}/authentication/login`, {
                cookie,
            })
            .then((resp) => {
                // login valid, push ahead
                let new_cookie = resp.data.cookie;
                Cookies.set("wiselink_session", new_cookie);
                let role = Cookies.get("role", null);
                setRole(role);
                handleDownload();
            })
            .catch((error) => {
                navigate("/");
            });
    }, []);

    const uploadOrReplaceS3File = (content) => {
        return new Promise((resolve, reject) => {
            // Configure the AWS SDK
            AWS.config.update({
                region: process.env.REACT_APP_REGION,
                accessKeyId: process.env.REACT_APP_ACCESS_KEY,
                secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
            });

            const s3 = new AWS.S3();
            const bucketName = "wiselink-sg";
            const fileKey = `instruction/instruction.html`;
            const fileContent = new Blob([content], { type: 'text/html' });

            // Check if the file exists
            s3.headObject({ Bucket: bucketName, Key: fileKey }, (err, data) => {
                if (err && err.code === 'NotFound') {
                    console.log("File does not exist, uploading new file...");
                    // Upload the file as it does not exist
                    s3.putObject(
                        {
                            Bucket: bucketName,
                            Key: fileKey,
                            Body: fileContent,
                        },
                        (err, data) => {
                            if (err) {
                                console.log("Error uploading file:", err);
                                reject(err); // Reject if upload fails
                            } else {
                                console.log("File has been uploaded.");
                                resolve(data); // Resolve on successful upload
                            }
                        }
                    );
                } else if (data) {
                    console.log("File exists, updating with new version...");

                    // Replace the file
                    s3.putObject(
                        {
                            Bucket: bucketName,
                            Key: fileKey,
                            Body: fileContent,
                        },
                        (err, data) => {
                            if (err) {
                                console.log("Error updating file:", err);
                                reject(err); // Reject if replace fails
                            } else {
                                console.log("File has been replaced.");
                                resolve(data); // Resolve on successful replacement
                            }
                        }
                    );
                } else {
                    console.log("Error:", err);
                    reject(err); // Reject if headObject fails for any reason other than "NotFound"
                }
            });
        });
    };

    const handleDownload = () => {
        AWS.config.update({
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        });

        const s3 = new AWS.S3();

        // Set up parameters
        const params = {
            Bucket: 'wiselink-sg',
            Key: 'instruction/instruction.html'
        };

        // Retrieve the file from S3
        s3.getObject(params, function (err, data) {
            if (err) {
                console.log(err, err.stack);
            } else {
                // Display content in a web page
                document.getElementById('content').innerHTML = data.Body.toString('utf-8');
            }
        });

    }

    return (
        <>
            {role === "ADMIN" && (
                <div>
                    <StyledHeader>Edit Instruction Page Content</StyledHeader>
                    <RichTextEditor
                        ref={rteRef}
                        extensions={[
                            StarterKit,
                            FontFamily,
                            TextStyle,
                            FontSize,
                            Underline,
                            Color,
                            Highlight,
                            Link.configure({
                                autolink: true,
                                linkOnPaste: true,
                                openOnClick: true,
                            }),
                            TextAlign.configure({
                                types: ['paragraph', 'heading'],
                            }),
                        ]}
                        renderControls={() => (
                            <MenuControlsContainer>
                                <MenuSelectFontFamily
                                    options={[
                                        { label: "MenuSelectFontFamily", value: "MenuSelectFontFamily MS, MenuSelectFontFamily" },
                                        { label: "Cursive", value: "cursive" },
                                        { label: "Monospace", value: "monospace" },
                                        { label: "Serif", value: "serif" },
                                    ]}
                                    aria-label="Font"
                                    emptyLabel="Font"
                                    tooltipTitle="Font"
                                    unsetOptionLabel="Default"
                                />
                                <MenuDivider />
                                <MenuSelectHeading />
                                <MenuDivider />
                                <MenuSelectFontSize />
                                <MenuDivider />
                                <MenuButtonBold />
                                <MenuButtonItalic />
                                <MenuButtonUnderline />
                                <MenuDivider />
                                <MenuButtonTextColor
                                    defaultTextColor="#000000"
                                    swatchColors={[
                                        { value: "#000000", label: "Black" },
                                        { value: "#ffffff", label: "White" },
                                        { value: "#888888", label: "Grey" },
                                        { value: "#ff0000", label: "Red" },
                                        { value: "#ff9900", label: "Orange" },
                                        { value: "#ffff00", label: "Yellow" },
                                        { value: "#00d000", label: "Green" },
                                        { value: "#0000ff", label: "Blue" },
                                    ]}
                                />
                                <MenuButtonHighlightColor
                                    swatchColors={[
                                        { value: "#595959", label: "Dark grey" },
                                        { value: "#dddddd", label: "Light grey" },
                                        { value: "#ffa6a6", label: "Light red" },
                                        { value: "#ffd699", label: "Light orange" },
                                        { value: "#ffff00", label: "Yellow" },
                                        { value: "#99cc99", label: "Light green" },
                                        { value: "#90c6ff", label: "Light blue" },
                                        { value: "#8085e9", label: "Light purple" },
                                    ]}
                                />
                                <MenuDivider />
                                <MenuSelectTextAlign />
                                <MenuButtonOrderedList />
                                <MenuButtonBulletedList />
                                <MenuDivider />
                                <MenuButtonHorizontalRule />
                                <MenuButtonUndo />
                                <MenuButtonRedo />
                                {/* Add more controls of your choosing here */}
                            </MenuControlsContainer>
                        )}
                    />
                    <Grid container item xs={12} lg={12} spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={4} lg={1}>
                            <StyledGreenButton onClick={() => {
                                const content = rteRef.current?.editor?.getHTML();
                                uploadOrReplaceS3File(content)
                                    .then(() => {
                                        window.location.reload();
                                    })
                                    .catch((error) => {
                                        console.log("error", error);
                                    });
                            }}>
                                Save
                            </StyledGreenButton>
                        </Grid>
                    </Grid>
                    <StyledHeader variant="h5" sx={{ mt: 3 }}>
                        Current Page Content :
                    </StyledHeader>
                </div>
            )}
            <div id="content"></div>
        </>
    );
};
export default Instruction;