import { TextField, styled } from "@mui/material";

export const StyledTextField = styled(TextField)`
    width: 100%;
    transition-duration: 0.6s;
    background-color: #fff;
    font-family: AirbnbCereal-Book;
    :hover fieldset {
        borderColor: #212b36;
        transitionDuration: 0.6s;
    },
    :focus {
        borderColor: #212b36;
    },
`;
