import React, { useEffect, useState } from "react";
import { List, ListItem, Typography, styled } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import routes from "../routes";
import Cookies from "js-cookie";
import useClasses from "../useClasses";

const StyledListItem = styled(ListItem)`
  color: #212b36;
  padding: 20px 25px;
  transition-duration: 0.2s;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  display: flex;
  flexgrow: 1;
  align-self: "center";
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  min-height: 44px;
  :hover {
    background-color: #e0e0e0;
    transition-duration: 0.2s;
  }
`;

const styles = (theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  "& .MuiDrawer-paperAnchorDockedLeft": {
    borderRightWidth: 0,
  },
  listWrapper: {
    marginTop: "20px",
  },
  list: {
    display: "flex",
    alignItems: "center",
    padding: "0px",
  },
  icon: {
    marginRight: "10px",
    fontSize: "15px",
    color: "#212b36",
  },
  text: {
    paddingLeft: "15px",
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
  },
  logo: {
    width: "70%",
    backgroundColor: "#fff",
  },
});

const Sidebar = () => {
  const classes = useClasses(styles);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // whenever path changes, check if there is a need to re-render the component.
    // the only time we need to re-render the component is when the path changes from / to /XXX
    let path_name = location.pathname;
    console.log("path: ", path_name);
    if (path_name == "/") {
      setIsLoggedIn(false);
      setIsAdmin(false);
      return;
    }
    // check cookie authenticaiton
    let cookie = Cookies.get("wiselink_session", null);
    let role = Cookies.get("role", null);
    if (cookie == null) {
      return;
    } else {
      setIsLoggedIn(true);
      if (role && role.toUpperCase() == "ADMIN") {
        setIsAdmin(true);
      }
    }
  }, [location.pathname]);

  return (
    <>
      {(() => {
        if (isLoggedIn) {
          return (
            <div className={classes.listWrapper}>
              {routes.map((item, index) => {
                if (
                  item.name == "Admin" ||
                  item.name == "Quotation Admin" ||
                  item.name == "Price List" ||
                  item.name == "Stock List" ||
                  item.name == "Supplier Price List"
                ) {
                  if (isAdmin) {
                    return (
                      <List
                        key={index}
                        component="div"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "0px",
                        }}
                      >
                        <StyledListItem
                          key={item.name}
                          component={Link}
                          to={item.path}
                        >
                          {item.icon}
                          <Typography
                            className={classes.text}
                            sx={{
                              fontSize: "0.9em",
                              fontFamily: "AirbnbCereal-Book",
                            }}
                          >
                            {item.name}
                          </Typography>
                        </StyledListItem>
                      </List>
                    );
                  }
                } else {
                  return (
                    <List
                      key={index}
                      component="div"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0px",
                      }}
                    >
                      <StyledListItem
                        key={item.name}
                        component={Link}
                        to={item.path}
                      >
                        {item.icon}
                        <Typography
                          className={classes.text}
                          sx={{
                            fontSize: "0.9em",
                            fontFamily: "AirbnbCereal-Book",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </StyledListItem>
                    </List>
                  );
                }
              })}
            </div>
          );
        }
      })()}
    </>
  );
};

export default Sidebar;
