import React from 'react'
import { Snackbar } from '@mui/material'
import { Alert as MuiAlert} from '@mui/lab';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Notification = (props) => {
    const { close, open, message, severity } = props
    return (
        <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={open} autoHideDuration={2000} onClose={close}>
            <Alert onClose={close} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default Notification