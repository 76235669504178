import React, { useCallback, useState, useEffect } from "react";
import {
  Box,
  Grid,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import axios from "axios";
import { API_GATEWAY_URL } from "../utils";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import SupplierPriceTable from "../components/SupplierPriceTable";
import { StyledHeader } from "../components/StyledHeader";
import { StyledTextField } from "../components/StyledTextField";
import _debounce from "lodash/debounce";

const SupplierPriceList = () => {
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [mpn, setMpn] = useState("");
  const [brand, setBrand] = useState("");
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

  function handleDebounceFn(mpn, supplier, page) {
    setOpen(true);
    axios
      .get(`${API_GATEWAY_URL}/supplier/price-list`, {
        params: {
          creator: Cookies.get("creator"),
          role: Cookies.get("role", "SALES"),
          mpn: mpn ? mpn : "",
          supplier: supplier ? supplier : "",
          page: page ? page : 1,
          page_size: 10,
        },
      })
      .then((response) => {
        setResults(response.data.price_list);
        setSearchResults(response.data.price_list);
        setTotalCount(response.data.total);
        setPage(page - 1);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setOpen(false);
      });
  }

  useEffect(() => {
    let cookie = Cookies.get("wiselink_session", null);
    if (!cookie) {
      navigate("/");
    }
    axios
      .post(`${API_GATEWAY_URL}/authentication/login`, { cookie })
      .then((resp) => {
        Cookies.set("wiselink_session", resp.data.cookie);
        debounceFn(null, null, 1);
      })
      .catch(() => navigate("/"));
  }, []);

  const filterSearch = (mpn, brand) => {
    setMpn(mpn.trim().toUpperCase());
    setBrand(brand.trim().toUpperCase());
    debounceFn(mpn, brand, 1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleDebounceFn(mpn, brand, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateSearchResults = (index, updatedRow) => {
    setSearchResults((prevResults) =>
      prevResults.map((row, i) => (i === index ? updatedRow : row))
    );
  };

  const deleteSearchResult = (index) => {
    setSearchResults((prevResults) =>
      prevResults.filter((_, i) => i !== index)
    );
  };  


  return (
    <Box sx={{ padding: "0em 1em", fontFamily: "AirbnbCereal-Medium" }}>
      <StyledHeader>Supplier Price List</StyledHeader>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <StyledTextField
            placeholder={"Brand"}
            value={brand}
            onChange={(e) => filterSearch(mpn, e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <StyledTextField
            placeholder={"MPN"}
            value={mpn}
            onChange={(e) => filterSearch(e.target.value, brand)}
          />
        </Grid>
      </Grid>
      <SupplierPriceTable
        searchResults={searchResults}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onUpdateSearchResults={updateSearchResults}
        onDeleteSearchResults={deleteSearchResult}
      />
      <Backdrop open={open} sx={{ color: "#fff", zIndex: 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default SupplierPriceList;
