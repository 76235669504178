import React, { useEffect, useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import axios from "axios";
import Notification from "../components/Notification";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { API_GATEWAY_URL } from "../utils";
import Logo from "../assets/logo_slim.png"; // Adjust the path to your image file
import useClasses from "../useClasses";
import { StyledTextField } from "../components/StyledTextField";

const styles = (theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text_font: {
    fontFamily: "AirbnbCereal-Book",
    fontSize: "1.1em",
  },
});

const Login = () => {
  const classes = useClasses(styles);
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");

  const handleSnackBar = (sev, msg) => {
    setSeverity(sev);
    setMessage(msg);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    Cookies.remove("wiselink_session");
    let cookie = Cookies.get("wiselink_session", null);
    if (cookie == null) return;
    axios
      .post(`${API_GATEWAY_URL}/authentication/login`, {
        cookie,
      })
      .then((resp) => {
        if (resp.status == 200) {
          // login valid, push ahead
          let new_cookie = resp.data.cookie;
          Cookies.set("wiselink_session", new_cookie);
          navigate("/quotation/create");
          return;
        }
        console.log(resp.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleLogin = () => {
    if (email.length == 0 || password.length == 0) {
      handleSnackBar("error", "Ensure fields are filled");
    } else {
    // perform standard login
    axios
      .post(`${API_GATEWAY_URL}/authentication/check-credentials`, {
        email,
        password,
      })
      .then((resp) => {
        // store role
        Cookies.set("role", resp.data.role);
        // store cookie > AWS will generate a valid cookie
        axios
          .post(`${API_GATEWAY_URL}/authentication/create-session`, {
            email,
          })
          .then((resp_cookie) => {
            Cookies.set("wiselink_session", resp_cookie.data.cookie);
            Cookies.set("email", email);
            Cookies.set("password", password);
            // redirect
            navigate("/quotation/create");
          })
          .catch((error) => {
            handleSnackBar("error", error.response.data);
          });
      })
      .catch((error) => {
        handleSnackBar("error", error.response.data);
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "75vh",
      }}
    >
      <img
        src={Logo}
        alt="Description"
        width={"300px"}
        style={{ marginBottom: "1em" }}
      />
      <Box className={classes.container}>
        <StyledTextField
          label="Email"
          style={{ margin: "1.5em", width: "300px" }}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          InputProps={{
            className: classes.text_font,
          }}
        />
        <StyledTextField
          type="password"
          label="Password"
          style={{ marginBottom: "1.5em", width: "300px" }}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            className: classes.text_font,
          }}
        />
      </Box>
      <Button
        onClick={handleLogin}
        sx={{
          marginTop: "1em",
          transition: "0.3s",
          padding: "0.6em 4em 0.6em 4em",
          borderRadius: "8px",
          fontFamily: "AirbnbCereal-Bold",
          fontSize: "1em",
          color: "#fff",
          borderWidth: "1px",
          backgroundColor: "#f99d32",
          "&:hover": {
            backgroundColor: "#f45239",
            transition: "0.3s",
          },
        }}
      >
        Login
      </Button>
      <Notification
        close={handleCloseSnackbar}
        open={openSnackbar}
        message={message}
        severity={severity}
      />
    </Box>
  );
};

export default Login;
